import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Box, FormControl, FormLabel } from "@mui/material";
import { ReactElement, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { emailRegex } from "../../constants";
import { Contact, ContactCategory, ContactType } from "../../store/cabinetApi/generated/crm";
import { CabDropdown } from "@CabComponents/CabDropdown";
import colors from "../../colors";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { CabExecPicker } from "@CabComponents/CabExecPicker";
import { Leader } from "../../store";
import CabAvatar from "@CabComponents/CabAvatar";
import { getLeaderIconSrc } from "../../utils/leaderUtils";



interface NewContactModalProps {
  open: boolean;
  onClose: () => void;
  contactCategories: ContactCategory[];
  contactTypes: ContactType[];
  onCreateContact: (contact: Omit<Contact, 'id'>, leaderIds?: Leader['id'][]) => Promise<void>;
  isCreatingContact: boolean;
  leaderOptions: Leader[];
}

interface FormInput {
  name: string;
  email: string;
  title: string;
  contactCategory?: number;
  contactType?: number;
  relationshipLeaders?: Leader['id'][];
}

const NewContactModal = ({
  open, onClose, contactCategories, contactTypes, onCreateContact, isCreatingContact, leaderOptions
}: NewContactModalProps): ReactElement => {

  const defaultValues: FormInput = {
    name: '',
    email: '',
    title: '',
    contactCategory: -1,
    contactType: -1,
    relationshipLeaders: [],
  };

  const contactForm = useForm<FormInput>({
    defaultValues: defaultValues
  });
  const { control, reset, handleSubmit: formSubmit, formState: { isValid } } = contactForm;

  const categoryOptions = [{id: -1, name: "None", color: colors.white800}, ...contactCategories];
  const typeOptions = [{id: -1, name: "None", color: colors.white800}, ...contactTypes];

  const cabExecOptions = useMemo(() => leaderOptions.map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [leaderOptions]);

  const handleSubmit = async (v: FormInput) => {
    await onCreateContact({
      name: v.name,
      primary_email: v.email,
      title: v.title,
      contact_category: v.contactCategory && v.contactCategory > 0 ? v.contactCategory : undefined,
      contact_type: v.contactType && v.contactType > 0 ? v.contactType : undefined,
      fields: {},
      leaders: []
    }, v.relationshipLeaders);
    reset();
    onClose();
  };

  return (
    <CabModal
      open={open}
      onClose={onClose}
      title={'Create New Contact'}
      closeIcon
      actionButtons={
        <Box display='flex' justifyContent={'space-between'} width='100%'>
          <CabButton color='error' onClick={() => reset()}>
            Discard
          </CabButton>
          <Box display='flex' gap={1}>
            <CabButton buttonType="tertiary" onClick={onClose}>
              Cancel
            </CabButton>
            <CabButton 
              buttonType="primary" 
              disabled={!isValid || isCreatingContact} 
              onClick={formSubmit(handleSubmit)}
            >
              Save
            </CabButton>
          </Box>
        </Box>
      }
    >
      <Box display='flex' flexDirection='column' gap={1} width={'100%'}>
        <FormControl>
          <FormLabel>Name*</FormLabel>
          <Controller name='name' control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <CabTextInput
                {...field}
                inputRef={ref}
                required
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email*</FormLabel>
          <Controller 
            name='email' control={control}
            rules={{ required: true, pattern: emailRegex }}
            render={({ field: { ref, ...field } }) => (
              <CabTextInput
                {...field}
                type='email'
                inputRef={ref}
                required
              />
            )}/>
        </FormControl>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <Controller name='title' control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <CabTextInput
                {...field}
                inputRef={ref}
                required
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Contact Category</FormLabel>
          <Controller name='contactCategory' control={control}
            render={({ field: { onChange, value } }) => (
              <CabDropdown<number>
                value={value || -1}
                onChange={onChange}
                options={categoryOptions.map(option => ({
                  value: option.id,
                  label: <CabToggleChip chipColor={option.color || colors.white800} label={option.name} 
                    selected={true}/>
                }))}
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Contact Type</FormLabel>
          <Controller name='contactType' control={control}
            render={({ field: { onChange, value } }) => (
              <CabDropdown<number>
                value={value || -1}
                onChange={onChange}
                options={typeOptions.map(option => ({
                  value: option.id,
                  label: <CabToggleChip chipColor={option.color || colors.white800} label={option.name} 
                    selected={true}/>
                }))}
              />
            )}
          />
        </FormControl>

        <FormControl sx={{ marginTop: 1 }}>
          <FormLabel>Relationships</FormLabel>
          <Controller name="relationshipLeaders" control={control}
            render={({ field: { ref, ...field } }) => (
              <CabExecPicker
                fullWidth
                multiple
                {...field}
                options={cabExecOptions}
                placeholder="Select a Teammate"
                sx={{ marginTop: '0px !important' }}
              />
            )}
          />
        </FormControl>

      </Box>
    </CabModal>
  );
};

export default NewContactModal;